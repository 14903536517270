.innerNav {
	color: grey;
	font-weight: bolder;
}

.brandNav {
	color: black;
	font-weight: bolder;
}

.successButtonNav {
	color: white;
	font-weight: bolder;
	font-size: 20px;
}

.background1Home {
	width: 100%;
	height: auto;
}

.textHome {
	font-family: NeoSans;
	font-size: 3em;
	color: #d9d9d9;
	font-weight: bold;
}

/* Container holding the image and the text */
.container {
	position: relative;
	text-align: center;
	color: white;
}

/* Bottom left text */
.bottom-left {
	position: absolute;
	bottom: 8px;
	left: 16px;
}

/* Top left text */
.top-left {
	position: absolute;
	top: 78px;
	right: 16px;
}

/* Top right text */
.top-right {
	position: absolute;
	top: 280px;
	left: 1006px;
}

/* Bottom right text */
.bottom-right {
	position: absolute;
	bottom: 8px;
	right: 16px;
}

/* Centered text */
.centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.textHomeSubHeading {
	font-family: "Copperplate", fantasy !important;
	color: yellowgreen;
	font-size: x-large !important;
}

.textHomeRow {
	font-family: "Garamond" serif !important;
}

.cardsHome {
	padding-top: 3%;
}

.whyusHome {
	padding-top: 3%;
}

.iconsCardsHome {
	font-size: 30px;
}

.whyusSubtextHome {
	padding-left: 5%;
	padding-right: 5%;
	text-align: center;
	text-align: justify;
}

.partnersCard {
	padding-top: 3%;
}

.partnerlogo {
	width: 50%;
	height: auto;
}

.queriesSub {
	width: 100%;
	height: 400px;
	margin-top: 3%;
}

.textQueries {
	font-family: NeoSans;
	font-size: 4em;
	color: white;
	font-weight: bold;
	margin-top: 3%;
	padding-left: 3%;
	padding-top: 5%;
}

.successButtonQueries {
	color: white;
	font-weight: bolder;
	font-size: 30px;
}

.iconPhone {
	color: yellowgreen;
}

.iconEmail {
	color: #0077b3;
}

.writeUsDialog {
	text-align: center;
	margin: 2%;
	font-weight: bolder;
}

.writeUssub {
	color: grey;
	font-weight: bolder;
	font-size: 18px;
}

.writeUsDialogButton {
	justify-content: center;
}
.writeUsDialogButtonContent {
	background-color: green;
	border: grey;
	font-weight: bolder;
}
